import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PasswordReset = () => {

    const { key } = useParams();

    const [email, setEmail] = useState();
    const [submittedKeyRequest, setSubmittedKeyRequest] = useState(false);
    const [newPasswordOnce, setNewPasswordOnce] = useState();
    const [newPasswordAgain, setNewPasswordAgain] = useState();
    const [submittedNewPassword, setSubmittedNewPassword] = useState(false);
    const [passwordChangeSuccessful, setPasswordChangeSuccessful] = useState();
    const [errors, setErrors] = useState([]);

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    
    const handleSubmitNewPassword = (e) => {
        e.preventDefault();

        const newErrors = [];

        if (!isValidPassword(newPasswordAgain)) {
            newErrors.push("Password requires at least eight characters and at least one letter and one number");
        }
        if (newPasswordOnce !== newPasswordAgain) {
            newErrors.push("Passwords do not match");
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        setSubmittedNewPassword(true);

        axios(process.env.REACT_APP_DOMAIN + `/passwordReset/${key}`, {
            method: 'POST',
            data: {
                password: newPasswordAgain
            }
        })
        .then(response => {
            setPasswordChangeSuccessful(true);
        })
        .catch(error => {
        });

        document.body.style.zoom="100%";
        window.scrollTo(0,0);
    }

    const isValidPassword = (password) => {
        //at least 8 characters, letters numbers and special characters allowed
        return /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(password)
    }

    const handleNewPasswordOnce = (e) => {
        e.preventDefault();
        setNewPasswordOnce(e.target.value);
    }

    const handleNewPasswordAgain = (e) => {
        e.preventDefault();
        setNewPasswordAgain(e.target.value);
    }

    const handleSubmitKeyRequest = (e) => {
        e.preventDefault();

        axios(process.env.REACT_APP_DOMAIN + '/passwordReset', {
            method: 'POST',
            data: {
                email: email
            }
        })
        .then(response => {
            setSubmittedKeyRequest(true);
        })
        .catch(error => {
        });

        document.body.style.zoom="100%";
        window.scrollTo(0,0);
    }

    return (
            <div className="default-body">
                {!key && !submittedKeyRequest &&
                    <div className="login-form paper" style={{width: "325px"}}>
                        <h2>Reset your password</h2>
                        <form onSubmit={handleSubmitKeyRequest}>
                            <label>Email</label>
                            <input 
                            type="text" 
                            placeholder="Email"
                            value={email}
                            onChange = {handleEmail}
                        />
                        <button 
                            className="login-button"
                            type="submit"
                            onSubmit={handleSubmitKeyRequest}
                        >
                            Send Reset Key
                        </button>
                        </form>
                    </div>
                }
                {!key && submittedKeyRequest &&
                    <div className="login-form paper" style={{width: "325px"}}>
                        <p style={{padding: "0px 10px"}}>Check your e-mail for a link to reset your password. This link will expire in 10 minutes.</p>
                    </div>
                }
                {key && !submittedNewPassword &&
                    <div className="login-form paper" style={{width: "325px"}}>
                        <h2>Reset your password</h2>
                        <form onSubmit={handleSubmitNewPassword}>
                            <label>New Password</label>
                            <input 
                                type="password" 
                                placeholder="Password"
                                value={newPasswordOnce}
                                onChange = {handleNewPasswordOnce}
                            />
                            <label>Retype New Password</label>
                            <input 
                                type="password" 
                                placeholder="Password Again"
                                value={newPasswordAgain}
                                onChange = {handleNewPasswordAgain}
                            />
                            <button 
                                className="login-button"
                                type="submit"
                            >
                                Submit
                            </button>
                        </form>
                        {errors.map((error) =>
                        <p className="warning">
                            {error}
                        </p>)}
                        </div>
                    }
                {passwordChangeSuccessful &&
                    <div className="login-form paper" style={{width: "325px"}}>
                        <p style={{padding: "0px 10px"}}>Password change was successful. Navigate to the portal to login.</p>
                    </div>
                }

            </div>
    )


}

export default PasswordReset;